import React, { useCallback, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultButton, MessageBarType } from '@fluentui/react'
import { appDispatch } from '@/bootstrap/redux'
import { MainLayout } from '@/shared/components'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import DocumentsBoxExplorer from './components/DocumentsBoxExplorer/DocumentsBoxExplorer'
import { selectAccountId } from '@/features/auth/redux/authSelectors'
import Tab from '@/shared/components/Tabs/Tab'
import TabPanel from '@/shared/components/Tabs/TabPanel'
import TabList from '@/shared/components/Tabs/TabList'
import BadgeWithText from '@/shared/components/BadgeLabel/BadgeWithText'
import DocumentSignatureList from './components/DocumentSignatureList/DocumentSignatureList'
import PageHeader from '@/shared/components/PageHeader'
import { getSharedDocumentLink } from '../SharedDocumentLink/SharedDocumentLink'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import DocumentSigning from './components/DocumentSigning/DocumentSigning'

export const TAB_EMBEDDED = 'docsEmbedded'
export const TAB_PENDING_SIGNATURE = 'pendingSignature'
export type DocumentsTabKey = 'all' | 'pendingSignature' | 'docsEmbedded'
export type DocumentsTabData = { tabId: string, panelId: string, label: JSX.Element }

export default function Documents({ history, location }: RouteComponentProps) {
  const accountId = useSelector(selectAccountId)
  const dispatch = useDispatch<appDispatch>()
  const [selectedTab, setSelectedTab] = useQueryParamSubscription<DocumentsTabKey>('tab', 'all')
  const [envelopeId, setEnvelopeId] = useQueryParamSubscription('envelopeId')
  const [documentCount, setDocumentCount] = useState(0)
  const [folderId, setFolderId] = useState<string>()
  const [folderName, setFolderName] = useState<string>()
  const [hasPermission, setHasPermission] = useState(true)

  const TABS: Array<[DocumentsTabKey, DocumentsTabData]> = [
    ['all', { tabId: 'docs-tab', panelId: 'docs-panel', label: <span>All Documents</span> }],
    [TAB_PENDING_SIGNATURE, {
      tabId: 'pending-signature',
      panelId: 'users-panel',
      label: (
        <BadgeWithText text='Pending Signature ' badgeContent={ documentCount }></BadgeWithText>
      )
    }],
    [TAB_EMBEDDED, { tabId: 'docs-embedded-tab', panelId: 'docs-embedded-panel', label: <span>Embedded</span> }],
  ]

  const onBoxFolderUpdate = (folderId: string, folderName: string, hasPermission: boolean) => {
    setFolderId(folderId)
    setFolderName(folderName)
    setHasPermission(hasPermission)
  }

  const getTabContent = (key: DocumentsTabKey) => {
    if (key === 'all') {
      return <DocumentsBoxExplorer history={history} location={location} onFolderUpdate={onBoxFolderUpdate} />
    }

    if (key === TAB_PENDING_SIGNATURE) {
      return <DocumentSignatureList accountId={accountId} setDocumentCount={setDocumentCount} headerLabel='Documents Pending Signature'/>
    }

    if (key === TAB_EMBEDDED) {
      return <DocumentSigning accountId={accountId}/>
    }
  }

  const isTabSelected = (key: DocumentsTabKey) => {
    if (key === selectedTab) {
      return true
    }

    if (key === TAB_PENDING_SIGNATURE && (selectedTab === TAB_EMBEDDED)) {
      return true
    }

    return false
  }

  const onTabClick = (key: DocumentsTabKey) => {
    setEnvelopeId(null)
    setFolderId(null)
    setFolderName(null)
    setHasPermission(false)
    setSelectedTab(key)
  }

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(getSharedDocumentLink(accountId, folderId))
      dispatch(createUIMessage({ 
        key: 'boxfoliderclipboard', 
        content: `Successfully copied a link to folder <b>${folderName}</b> to your clipboard`,
        messageBarType: MessageBarType.success
      }))
    } catch (error) {
      console.error('Error while creating share link.', error)
    }
  }, [dispatch, folderName])

  return (
    <>
      <IntlProvider locale='en'>
        <MainLayout className='documents-wrapper'>
          <PageHeader
            label='Documents' 
            className='documents-explorere-header'
            action={(selectedTab === 'all') && folderId && hasPermission ? (
              <DefaultButton text='Copy link to folder' onClick={handleCopy} />
            ) : null}
          />
          <TabList>
            {TABS.map(([key, tab]) => (
              <Tab
                key={key}
                id={tab.tabId}
                panelId={tab.panelId}
                isSelected={isTabSelected(key)}
                label={tab.label}
                onClick={() => { onTabClick(key) }}
              />
            ))}
          </TabList>
          {TABS.map(([key, tab]) => (
            <TabPanel key={key} id={tab.panelId} tabId={tab.tabId} isActive={selectedTab === key}>
              <div style={{ 'marginTop': '30px' }}>{getTabContent(key)}</div>
            </TabPanel>
          ))}
        </MainLayout>
      </IntlProvider>
    </>
  )
}