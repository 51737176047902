import { IComboBoxOption, IComboBoxStyles } from '@fluentui/react'
import { Dropdown, IDropdownOption } from '@fluentui/react/lib/Dropdown'

import './TopHoldingsSizeSelector.scss'

export interface TopHoldingsSizeSelectorProps {
  size: number,
  onSizeChange: (size: number) => void
}

const TopHoldingsSizeSelector = ({
  size,
  onSizeChange,
}: TopHoldingsSizeSelectorProps) => {
  const dropdownData: IDropdownOption[] = [
    {
      'key': 5,
      'text': 'Top 5',
    },
    {
      'key': 10,
      'text': 'Top 10',
    },
    {
      'key': 15,
      'text': 'Top 15',
    }
  ]

  const comboBoxStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 80,
      outline: 'none',
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 80
    },
  }

  const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IComboBoxOption) => {
    onSizeChange(Number(`${option.key}`))
  }
  
  return (
    <Dropdown
      className='TopHoldingsSizeSelector'
      defaultSelectedKey={size}
      options={dropdownData}
      styles={comboBoxStyles}
      onChange={handleDropdownChange}
      calloutProps={{
        className: 'TopHoldingsSizeSelectorOptions'
      }}
    />
  )
}

export default TopHoldingsSizeSelector