import React, { useState } from 'react'
import { Callout, DirectionalHint, Icon, VerticalDivider } from '@fluentui/react'
import './SignerCell.scss'
export interface SignersCellProps {
  signers: Array<{ name: string; status: string }>
  filter: string
}

export default function SignersCell({ signers, filter }: SignersCellProps) {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false)
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

  if (!signers || signers.length === 0) {
    return <span></span> // Return a valid JSX element
  }

  // Apply filter only if it's not an empty string
  const filteredSigners = filter
    ? signers.filter(s => s.status.toLowerCase() === filter)
    : signers

  // Map the filtered signers to their names
  const names = filteredSigners.map(s => s.name).join(', ')

  return (
    <div className='signers-cell'>
      <span 
        className='span-name' 
        onMouseEnter={(e) => {
          setTargetElement(e.currentTarget as HTMLElement)
          setIsCalloutVisible(true)
        }}
        onMouseLeave={() => setIsCalloutVisible(false)}
      >{names || ""}</span>

      {isCalloutVisible && (
        <Callout
          target={targetElement}
          onDismiss={() => setIsCalloutVisible(false)}
          directionalHint={DirectionalHint.topCenter}
          gapSpace={10}
          setInitialFocus
        >
          <div className='signers-cell-callout' style={{ padding: '10px' }}>
            <ul>
              {filteredSigners.map((signer, index) => (
                <li key={index}>{signer.name}</li>
              ))}
            </ul>
          </div>
        </Callout>
      )}
    </div>
  )
}