import { useSearchTeamMemberMutation } from '@/shared/api/services/teamService'
import { IBasePickerStyles, IBasePickerSuggestionsProps, ICalloutProps, IPersonaProps, NormalPeoplePicker } from '@fluentui/react'
import { useTranslation } from 'react-i18next'

export interface TeamSelectorModel {
  accountId?: string,
  caId: number,
  id: string,
  peopleId: number,
  fullName: string,
  jobTitle: string,
  email: string,
  image?: string,
  imageContentType?: string,
  sortIndex?: number,
  contactKey?: number,
  contactId?: string,
}

export interface TeamSelectorProps {
  comboStyle?: Partial<IBasePickerStyles>,
  calloutProps?: ICalloutProps,
  classNameCustom?: string,
  maxItems?: number,
  selectedItems?: IPersonaProps[],
  teamSelectorSuggestionsProps?: IBasePickerSuggestionsProps,
  onFilter: (filter: string) => Promise<TeamSelectorModel[]>,
  onSelect: (teamMembers: TeamSelectorMemberProps[]) => void,
}

export interface TeamSelectorMemberProps extends IPersonaProps {
  sourceMember: TeamSelectorModel,
}

export default function TeamSelector({ comboStyle, calloutProps, classNameCustom, maxItems, selectedItems, teamSelectorSuggestionsProps, onFilter, onSelect }: TeamSelectorProps) {
  const { t } = useTranslation('messages')
  const [getTeamMembers] = useSearchTeamMemberMutation()
  const pickerSuggestionsProps = teamSelectorSuggestionsProps || {
    suggestionsHeaderText: 'Suggested People',
    noResultsFoundText: t('pickerNoResultsFoundText'),
    loadingText: t('pickerLoadingText'),
    suggestionsAvailableAlertText: t('pickerSuggestionsAvailableAlertText'),
    suggestionsContainerAriaLabel: 'Suggested People'
  } as IBasePickerSuggestionsProps

  const onFilterChanged = async (filterText: string, selectedItems: IPersonaProps[]): Promise<TeamSelectorMemberProps[]> => {
    if (filterText && filterText.length >= 3) {
      const data = await onFilter(filterText)
      const sourceData = data.map((user) => ({
        text: user.fullName,
        secondaryText: user.jobTitle,
        itemID: user.id,
        sourceMember: user,
      }))
      return sourceData
    } else {
      return []
    }
  }

  const onChange = (users: TeamSelectorMemberProps[]) => {
    onSelect(users)
  }

  return (
    <NormalPeoplePicker
      className={`team-selector-${classNameCustom}`}
      selectedItems={selectedItems}
      onResolveSuggestions={onFilterChanged}
      pickerSuggestionsProps={pickerSuggestionsProps}
      onChange={onChange}
      selectionAriaLabel={t('pickerSelectedContacts')}
      removeButtonAriaLabel={t('pickerRemove')}
      itemLimit={maxItems}
      styles={{
        ...comboStyle
      }}
      pickerCalloutProps={{
        ...calloutProps
      }}
    />
  )
}