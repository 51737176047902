
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import TeamSelector, { TeamSelectorMemberProps, TeamSelectorModel } from '@/shared/components/TeamSelector/TeamSelector'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'

import './ClientUserSelector.scss'
import { IBasePickerStyles } from '@fluentui/react'

export interface ClientUserSelectorProps {
  accountId: string,
  onSelectedItems: (selectedItems: TeamSelectorModel[]) => void,
  teamSelectorWidth?: number
}

const ClientUserSelector = forwardRef(({ accountId, onSelectedItems,  teamSelectorWidth }: ClientUserSelectorProps, ref) => {
  const [ selectedItems, setSelectedItems ] = useState([] as TeamSelectorMemberProps[])
  const { data: contacts, isLoading, isUninitialized } = useGetContactsByAccountIdQuery(accountId)

  useImperativeHandle(ref, () => ({
    resetItems: () => setSelectedItems([]),
  }));

  
  const comboBoxStyles: Partial<IBasePickerStyles> = {
    root: {
      width: teamSelectorWidth
    }
  }

  const calloutProps = {
    calloutWidth: teamSelectorWidth,
    target: `.team-selector-clientUser`,
  }
  //changed filter method to get results on autocomplete
  const clientUSerServiceFilter = async (filter: string) => {
    if (!contacts || !contacts.length) {
      return []
    }

    return contacts.filter(c => {
      const { firstName, lastName } = c
      return `${firstName} ${lastName}`.toUpperCase().includes(filter.toUpperCase()) &&
      selectedItems.findIndex(si => si.sourceMember.fullName === `${firstName} ${lastName}`) === -1
    }).map(c => ({
      accountId,
      fullName: `${c.firstName?.trim()}${c.middleName?.trim() ? ' ' + c.middleName : ''}${c.lastName?.trim() ? ' ' + c.lastName?.trim() : ''}`,
      email: c.email1,
      id: c.contactId,
      contactKey: c.contactKey,
      contactId: c.contactId,
      caId: c.caid,
    })) as TeamSelectorModel[]
  }

  const onSelect = (selectedOptions: TeamSelectorMemberProps[]) => {
    setSelectedItems(selectedOptions)
    onSelectedItems(selectedOptions.map(v => v.sourceMember))
  }

  return (
    <TeamSelector classNameCustom='clientUser' comboStyle={comboBoxStyles} calloutProps={calloutProps} selectedItems={selectedItems} onFilter={clientUSerServiceFilter} onSelect={onSelect} />
  )
})

ClientUserSelector.displayName = 'ClientUserSelector';
export default ClientUserSelector
