import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link as RRLink, useHistory } from 'react-router-dom'
import { ColumnActionsMode, Link, Stack } from '@fluentui/react'
import { Section, Table } from '@/shared/components'
import { IModifiedColumn } from '@/shared/components/Table/types'
import { useGetDocuSignSummaryQuery, useUpdateStatusMutation } from '@/shared/api/services/docuSignService'
import Confirmation from '@/shared/components/Confirmation'
import { selectIsImpersonating } from '@/features/auth/redux/authSelectors'
import SkeletonDashboardDocuments from '../DashboardDocumentWidget/SkeletonDashboardDocuments'

import './PendingDocumentsSignature.scss'

export interface PendingDocumentsSignatureProps {
  accountId: string,
  setDocumentCount: (count: number) => void,
}

export default function PendingDocumentsSignature({ accountId, setDocumentCount }: PendingDocumentsSignatureProps) {
  const headerLabel = 'Documents Pending Signature'
  const isUninitialized = false
  const isImpersonating = useSelector(selectIsImpersonating)
  const { data, isLoading, isFetching, isError } = useGetDocuSignSummaryQuery({ accountId })
  const [pendingCount, setPendingCount] = useState(0)
  const [isDialogShown, setIsDialogShown] = useState(false)
  const [docusignIdParam, setDocusignIdParam] = useState<string>()
  const history = useHistory()  
  const [updateStatus] = useUpdateStatusMutation()  
  const pendingDocuments = data ? data.filter(item => item.viewURL !== null && item.documentStatus !='Expired' ) : []

  useEffect(() => {
    if (data) {
      const count = data.filter(item => item.viewURL !== null && item.documentStatus !='Expired' ).length
      setDocumentCount(count)
      setPendingCount(count)
    } else {
      setDocumentCount(0)
      setPendingCount(0)
    }
  }, [data, setDocumentCount])

  const columns: IModifiedColumn[] = [
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'NAME',
      key: 'name',
      fieldName: 'templateName',
      className: 'name name-fixed-column',
      headerClassName: 'name name-fixed-column',
    },
    {
      flexGrow: 0,
      minWidth: 180,
      name: 'DATE CREATED',
      key: 'date',
      fieldName: 'creationDate',
      className: 'date',
      headerClassName: 'date',
      onRender: (data: any) => {
        const { creationDate } = data
        const formattedDate = dayjs(creationDate).format('LL')
        return (
          <time className="c-document-signature-setup__date" dateTime={formattedDate}>
            {formattedDate}
          </time>
        )
      },
    },
    {
      flexGrow: 0,
      minWidth: 100,
      name: '',
      key: 'viewURL',
      fieldName: 'viewURL',
      className: 'actions fixed-column',
      headerClassName: 'actions',
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      onRender: (data: any) => {
        // [CP-679] - CA Users impersonating client users should not be able to Sign Document
        if (isImpersonating) {
          return ''
        }

        const { docusignId, documentStatus, viewURL } = data

        if (!viewURL || documentStatus === 'Expired') {
          return ''
        }

        return (
          <Stack horizontal horizontalAlign="end" className="c-clients-table__actions" margin-left='50px'>
            <Link onClick={() => {
              setIsDialogShown(true)
              setDocusignIdParam(docusignId)
             }}>Sign Document</Link>
          </Stack>
        )
      },
    }
  ]

  return (
    <>
      {!isFetching ? (pendingCount ?
        (<Section className='c-pending-documents-signature' title={headerLabel}>
          <Table
            shimmerLineCount={5}
            shimmerIsEnabled={isLoading || isUninitialized}
            compact={true}
            columns={columns}
            data={pendingDocuments || []}
          />
        </Section>) : (
          <span className='c-pending-documents-signature-not-found'>No documents found for signature.</span>
        )) : (<SkeletonDashboardDocuments></SkeletonDashboardDocuments>)
      }
      <Confirmation
        hidden={!isDialogShown}
        title='Sign Document'
        subText='Are you sure you want to start the signing process?'
        cancelButtonLabel='Cancel'
        confirmButtonLabel='Start'
        handleDismiss={() => {
          setIsDialogShown(false)
          setDocusignIdParam(undefined)
        }}
        handleNo={() => {
          setIsDialogShown(false)
          setDocusignIdParam(undefined)
        }}
        handleYes={() => {
          updateStatus({ docusignId: docusignIdParam, newStatus: 'Clicked' })
            .then(() => {
              history.push(`/documents?tab=docsEmbedded&docusignId=${docusignIdParam}`)
              setIsDialogShown(false)
              setDocusignIdParam(undefined)
            })
            .catch((error: any) => {
              console.error('Error updating status:', error)
              setIsDialogShown(false)
              setDocusignIdParam(undefined)
            })
        }}
      />
    </>
  )
}
