import { format, isValid, isSameDay, isAfter, parse, lastDayOfMonth, addDays, addMonths, addYears, isBefore, differenceInMonths } from 'date-fns'
import _ from 'lodash'

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy'
export const DEFAULT_FULL_DATE_FORMAT = 'MMM dd yyyy'
export const DEFAULT_DATETIME_FORMAT = 'MMM-dd-yyyy hh:mm aaa'
export const SERVER_DATETIMME_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss'
export const TODAY_PLACEHOLDER = 'Today'
export const DAY_IN_MILLIS = 24 * 3600 * 1000
export const DEFAULT_EXCEL_DATE_FORMAT = 'MM/dd/yyyy'
export const DEFAULT_EXCEL_DATETIME_FORMAT = 'MM/dd/yyyy hh:mm:ss aaa'

export const DateFormatter = (
  value?: Date,
  dateFormat = DEFAULT_DATE_FORMAT
) => {
  if (!value || !isValid(value)) {
    return ''
  }
  return format(value, dateFormat)
}

export const ExcelDateFormatter = (
  value?: Date,
  dateFormat = DEFAULT_EXCEL_DATE_FORMAT
) => {
  if (!value || !isValid(value)) {
    return ''
  }
  return format(value, dateFormat)
}

export const StringDateFormatter = (
  value?: Date,
  dateFormat = 'yyyy-MM-dd'
) => {
  if (!value || !isValid(value)) {
    return ''
  }
  return format(value, dateFormat)
}

export const DateTimeFormatter = (
  value?: Date,
  dateTimeFormat = DEFAULT_DATETIME_FORMAT
) => {
  if (!value || !isValid(value)) {
    return ''
  }
  return format(value, dateTimeFormat)
}

export const ExcelDateTimeFormatter = (
  value?: Date,
  dateTimeFormat = DEFAULT_EXCEL_DATETIME_FORMAT
) => {
  if (!value || !isValid(value)) {
    return ''
  }
  return format(value, dateTimeFormat)
}

export const ServerDateParser = (value?: string): Date | undefined => {
  return FromLocalDateToDate(value)
}

export const AdaptDateAndTimeFields = <T>(
  obj: any,
  ...fields: string[]
): T | T[] => {
  if (_.isArray(obj)) {
    return _.map(obj, (o) => AdaptDateAndTimeFields(o as T, ...fields)) as T[]
  }
  const adapted = _.reduce(
    fields,
    (acct, field) => ({
      ...acct,
      [field]: ServerDateParser(_.get(obj, field)),
    }),
    {}
  )
  return {
    ...obj,
    ...adapted,
  } as T
}

export const AdaptDateAndTimeFieldsMapper = <T>(
  ...fields: string[]
): ((obj: any) => T) => {
  return (obj: any) => AdaptDateAndTimeFields(obj, ...fields) as T
}

export const FromDateToLocalDate = (date: Date | null) => {
  if (date) {
    const fixValue = (value: number) => (value < 10 ? '0' + value : value)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    return `${year}-${fixValue(month)}-${fixValue(day)}T${fixValue(
      hour
    )}:${fixValue(minute)}:${fixValue(second)}.00`
  }

  return undefined
}

export const FromLocalDateToDate = (localDate: any) => {
  if (localDate) {
    if (typeof localDate === 'string') {
      const localDateRegex = /([0-9]{4})-([0-9]{2})-([0-9]{2})(T([0-9]{2}):([0-9]{2}):([0-9]{2})(.*)?)?/
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_text, year, month, day, hour, minute, second] = localDate.match(
        localDateRegex
      ) as any[]
      return new Date(
        Number(year),
        Number(month) - 1,
        Number(day),
        Number(hour) || 0,
        Number(minute) || 0,
        Number(second) || 0
      )
    } else {
      const { year, monthValue, dayOfMonth, hour, minute, second } = localDate
      return new Date(
        year,
        monthValue - 1,
        dayOfMonth,
        hour || 0,
        minute || 0,
        second || 0
      )
    }
  }

  return undefined
}

export const FromStringDateToDate = (stringDate: any) => {
  if (stringDate) {
    const stringDateRegex = /([0-9]{4})-([0-9]{2})-([0-9]{2})/
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_text, year, month, day] = stringDate.match(
      stringDateRegex
    ) as any[]
    return new Date(Number(year), Number(month) - 1, Number(day))
  }
  return stringDate
}

export const validateLiquidationDate = (inception: Date, liquidation: Date) => {
  if (!inception || !liquidation) {
    return true
  } else {
    const sameDay = isSameDay(inception, liquidation)
    const after = isAfter(liquidation, inception)
    return after || sameDay
  }
}

export const checkLeapYear = (date?: Date) => {
  if (date) {
    return new Date(date.getFullYear(), 1, 29).getDate() === 29
  }

  return false
}

export const formatDate = (date: Date, dateFormat = 'yyyy-MM-dd') => {
  if (date) {
    return format(date, dateFormat)
  }
  return ''
}

export const parseFormattedDate = (date: string, dateFormat = 'yyyy-MM-dd') => {
  if (date) {
    return parse(date, dateFormat, new Date())
  }
}

export const getMonthEnd = (date: Date) => {
  const monthEndDate = format(lastDayOfMonth(date), 'yyyy-MM-dd')
  return parseFormattedDate(monthEndDate)
}

export const getPreviousMonthEnd = (date: Date) => {
  const firstDayOffMonth = format(date, 'yyyy-MM-01')
  const firstDay = parseFormattedDate(firstDayOffMonth)
  const previousMonthEnd = addDays(firstDay, -1)
  return previousMonthEnd
}

export const getPreviousTwoMonthEnd = (date: Date) => {
  const firstDayOffMonth = format(date, 'yyyy-MM-01')
  const firstDay = parseFormattedDate(firstDayOffMonth)
  const previousMonthEnd = addMonths(firstDay, -1)
  const monthEnd = addDays(previousMonthEnd, -1)
  return monthEnd
}

export const getMaxAsOfDate = () => {
  return getPreviousTwoMonthEnd(new Date())
}

export const getMaxQuarterlyAsOfDate = () => {
  let maxAsOfDate = getMaxAsOfDate()

  // Adjust the date to the nearest previous quarter end (March, June, September, December)
  const month = maxAsOfDate.getMonth()

  if (month !== 2 && month !== 5 && month !== 8 && month !== 11) {
    // If not March, June, September, or December, adjust to the previous quarter end
    if (month < 2) {
      maxAsOfDate = new Date(maxAsOfDate.getFullYear() - 1, 11, 31) // Previous December
    } else if (month < 5) {
      maxAsOfDate = new Date(maxAsOfDate.getFullYear(), 2, 31) // March
    } else if (month < 8) {
      maxAsOfDate = new Date(maxAsOfDate.getFullYear(), 5, 30) // June
    } else {
      maxAsOfDate = new Date(maxAsOfDate.getFullYear(), 8, 30) // September
    }
  }

  return maxAsOfDate
}

export const getMinAsOfDate = () => {
  const maxAsOfDate = getMaxAsOfDate()
  const minAsOfDate = addYears(maxAsOfDate, -5)
  const minAsOfDateMonthEnd = getMonthEnd(minAsOfDate)
  return minAsOfDateMonthEnd
}

export const getFormattedMaxAsOfDate = () => {
  return formatDate(getMaxAsOfDate())
}

export const getFormattedMaxQuarterlyAsOfDate = () => {
  return formatDate(getMaxQuarterlyAsOfDate())
}

export const isDateMonthEnd = (date: Date) => {
  const monthEndDate = getMonthEnd(date)
  return formatDate(monthEndDate) === formatDate(date)
}

export const isFormattedDateMonthEnd = (date: string) => {
  const parsedDate = parseFormattedDate(date)
  return date === formatDate(getMonthEnd(parsedDate))
}

export const isDateValid = (date: Date) => {
  return isDateMonthEnd(date) && (!isAfter(date, getMaxAsOfDate()))
}

export const isFormattedDateValid = (date: string) => {
  const parsedDate = parseFormattedDate(date)

  // date not valid for non month end
  if (!isDateMonthEnd(parsedDate)) {
    return false
  }

  // date not valid if older than min as of date
  if (isBefore(parsedDate, getMinAsOfDate())) {
    return false
  }
  
  // date not valid if more recent than max as of date
  if (isAfter(parsedDate, getMaxAsOfDate())) {
    return false
  }

  return true
}

export const getDifferenceInMonths = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return undefined
  }

  return Math.abs(differenceInMonths(endDate, startDate))
}
