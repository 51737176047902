import Skeleton from 'react-loading-skeleton'

const SkeletonDashboardDocuments = () => {
  return (
    <div className='c-performance-skeleton' role="presentation">
      <div className='client-portfolio-skeleton__left'>
        <div className='c-insight-skeleton__left__title'>
          <Skeleton width="100%" count={2} />
        </div>
        <Skeleton width={80} />
        <div className='c-insight-skeleton__left__description'>
          <Skeleton width="100%" count={3} />
        </div>
      </div>
    </div>
  )
}

export default SkeletonDashboardDocuments