import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { selectClaims } from '@/features/auth/redux/authSelectors'
import { 
  DATA_DOG_APP_ID,
  DATA_DOG_CLIENT_TOKEN,
  DATA_DOG_SERVICE,
  DATA_DOG_SITE,
  DATA_DOG_VERSION,
  DEPLOYED_ENV,
} from '@/environment/environment.constants'
import { IInsightsPostView } from '@/shared/types/swagger'

export interface DatadogViewDocumentFolderAction {
  accountId: string,
  clientId: string,
  accountName: string,
  folderId: string,
  folderName: string,
}

export interface DatadogViewDocumentFileAction {
  accountId: string,
  clientId: string,
  accountName: string,
  folderId: string,
  folderName: string,
  fileName: string,
  fileId?: string,
}

export interface DatadogDownloadDocumentFileAction {
  accountId: string,
  clientId: string,
  accountName: string,
  folderId: string,
  folderName: string,
  fileName: string,
  fileId?: string,
}

export interface DatadogUploadDocumentFileAction {
  accountId: string,
  clientId: string,
  accountName: string,
  folderId: string,
  folderName: string,
  fileName: string,
  fileId: string,
}

const DatadogSessionProvider = () => {
  const { pathname, search } = useLocation()
  const userClaims = useSelector(selectClaims)

  /**
   * Handler for Datadog RUM setup
   */
  useEffect(() => {
    // inits Datadog RUM
    datadogRum.init({
      applicationId: DATA_DOG_APP_ID,
      clientToken: DATA_DOG_CLIENT_TOKEN,
      site: DATA_DOG_SITE,
      service: DATA_DOG_SERVICE,
      version: DATA_DOG_VERSION,
      env: DEPLOYED_ENV,
      sampleRate: 100,
      trackInteractions: true,
      silentMultipleInit: true,
      useSecureSessionCookie: true,
      defaultPrivacyLevel: 'mask-user-input',
      trackViewsManually: true,
      allowedTracingUrls: [(url) => url.includes('api.clientportal.cambridgeassociates.com')],
    })

    // starts Datadog RUM session recording
    datadogRum.startSessionReplayRecording()

    return () => {
      // stops Datadog RUM session recording
      datadogRum.stopSessionReplayRecording()
    }
  }, [])

  /**
   * Handler for Datadog user session setup
   */
  useEffect(() => {
    if (userClaims) {
      const id = userClaims.CAID
      const name = `${userClaims.FirstName} ${userClaims.LastName}`.trim()
      const email = userClaims.email
      const userType = userClaims.UserType
      const isImpersonating = userClaims.isImpersonating
      const isCAEmployee = userClaims.CAEmployee
      const clientId = userClaims.ClientId
      const clientName = userClaims.ClientName
      const user = {
        id,
        name,
        email,
        userType,
        isImpersonating,
        isCAEmployee,
        clientId,
        clientName,
      }

      // updates user session
      datadogRum.setUser(user)
    }
  }, [userClaims])

  /**
   * Handler for Datadog view register
   */
  useEffect(() => {
    let viewName = '';
    
    // Dashboard
    if (!pathname || pathname === '/' || pathname === '/dashboard') {
      viewName = 'Dashboard'
    } else if (pathname.match(/\/dashboard\/onboarding/)) {
      viewName = 'Onboarding Dashboard'
    } else if (pathname.match(/\/onboarding\/[a-zA-Z0-9\-]+/)) {
      viewName = 'Onboarding Dashboard Editor'
    } else if (pathname.match(/\/clients\/[a-zA-Z0-9\-]+/)) {
      // initial view name
      viewName = 'Client'

      // selected tab property
      const tab = (search || '').match(/tab\=([^&]+)/)
      const selectedTab = tab && tab[1] || ''
      
      if (selectedTab) {
        switch (selectedTab) {
          case 'users':
            viewName += ' - Users'
            break
          case 'docs':
            viewName += ' - Documents'
            break
          case 'messages':
            viewName += ' - Messages'
            break
          case 'settings':
            viewName += ' - Settings'
            break
          case 'admin':
            viewName += ' - Admin'
            break
        }
      } else {
        viewName += ' - Users'
      }
    } else if (pathname.match(/\/clients/)) {
      viewName = 'Admin - Clients'
    } else if (pathname.match(/\/documents/) || pathname.match(/\/documents\/[0-9]+/) || pathname.match(/\/sharedDocumentLink\/[a-zA-Z0-9\-]+/)) {
      viewName += 'Documents'
    } else if (pathname.match(/\/messages/)) {
      viewName += 'Messages'
    } else if (pathname.match(/\/support/)) {
      viewName += 'Information & Help'
    } else if (pathname.match(/\/insights/)) {
      viewName += 'Insights'
    } else if (pathname.match(/\/more/) || pathname.match(/\/more\/[0-9]+/)) {
      viewName += 'More'
    } else if (pathname.match(/\/profile/)) {
      viewName += 'My Profile'
    } else if (pathname.match(/\/events/)) {
      viewName += 'Events'
    } else if (pathname.match(/\/notifications/)) {
      viewName += 'Notifications'
    } else if (pathname.match(/\/surveyLinks/)) {
      viewName += 'Survey Links'
    } else if (pathname.match(/\/portfolio\/[0-9]+/)) {
      // initial view name
      viewName = 'Portfolio Dashboard'

      // selected tab property
      const tab = (search || '').match(/tab\=([^&]+)/)
      const selectedTab = tab && tab[1] || ''
      
      if (selectedTab) {
        switch (selectedTab) {
          case 'portfolioHome':
            viewName += ' - Portfolio Home'
            break
          case 'performance':
            viewName += ' - Performance'
            break
          case 'trades':
            viewName += ' - Trades'
            break
        }
      } else {
        viewName += ' - Portfolio Home'
      }
    }
  
    if (viewName) {
      datadogRum.startView(viewName)
    } else {
      datadogRum.startView(pathname)
    }
  }, [pathname, search])

  // returns no content
  return (null)
}

export const addDatadogAction = (actionName: string, actionDetails?: any) => {
  try {
    datadogRum.addAction(actionName, actionDetails)
  } catch (error) {
    console.error('Failed to log Datadog ' + actionName + ' action.', error)
  }
}

export const trackDocumentViewFolderAction = ({ accountId, clientId, accountName, folderId, folderName  }: DatadogViewDocumentFolderAction) => {
  addDatadogAction('View Folder', {
    accountId,
    clientId,
    accountName,
    folderId,
    folderName,
  })
}

export const trackDocumentViewFileAction = ({ accountId, clientId, accountName, folderId, folderName, fileId, fileName  }: DatadogViewDocumentFileAction) => {
  addDatadogAction('View File', {
    accountId,
    clientId,
    accountName,
    folderId,
    folderName,
    fileId,
    fileName,
  })
}

export const trackDocumentUploadFileAction = ({ accountId, clientId, accountName, folderId, folderName, fileId, fileName }: DatadogUploadDocumentFileAction) => {
  addDatadogAction('Upload File', {
    accountId,
    clientId,
    accountName,
    folderId,
    folderName,
    fileId,
    fileName,
  })
}

export const trackDocumentDownloadFileAction = ({ accountId, clientId, accountName, folderId, folderName, fileId, fileName  }: DatadogDownloadDocumentFileAction) => {
  addDatadogAction('Download File', {
    accountId,
    clientId,
    accountName,
    folderId,
    folderName,
    fileId,
    fileName,
  })
}

export const trackInsightViewAction = (insight: IInsightsPostView) => {
  if (insight) {
    addDatadogAction('Read Insight', {
      insightId: insight.id,
      insightTitle: insight.title,
      insightLink: insight.link,
    })
  }
}

export const trackFeaturedInsightViewAction = (insight: IInsightsPostView) => {
  if (insight) {
    addDatadogAction('Read Featured Insight', {
      insightId: insight.id,
      insightTitle: insight.title,
      insightLink: insight.link,
    })
  }
}

export const trackInsightDownloadAction = (insight: IInsightsPostView) => {
  if (insight) {
    addDatadogAction('Download Insight Attachment', {
      insightId: insight.id,
      insightTitle: insight.title,
      insightLink: insight.link,
      insightAttchmentLink: insight.downloadLink,
    })
  }
}

export default DatadogSessionProvider
