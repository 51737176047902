import React, { useState } from 'react'
import Tab from '@/shared/components/Tabs/Tab'
import TabPanel from '@/shared/components/Tabs/TabPanel'
import TabList from '@/shared/components/Tabs/TabList'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import ClientDocumentBoxExplorer from './components/ClientDocumentBoxExplorer/ClientDocumentBoxExplorer'
import DocumentSignatureSetup from './components/DocumentSignature/DocumentSignatureSetup/DocumentSignatureSetup'
import DocumentSignatureReview from './components/DocumentSignature/DocumentSignatureReview/DocumentSignatureReview'
import BadgeWithText from '@/shared/components/BadgeLabel/BadgeWithText'
import CADocumentSigning from './components/CADocumentSigning/CADocumentSigning'

import './ClientDocuments.scss'

export const TAB_EMBEDDED = 'docsEmbedded'
export const TAB_SIGATURE_DOCUMENTS = 'signatureDocuments'
export type ClientDocumentsTabKey = 'all' | 'setup' | 'signatureDocuments' | 'docsEmbedded'
export type ClientDocumentsTabData = { tabId: string, panelId: string, label: JSX.Element }

export type ClientDocumentsProps = {
  accountid: string
  rootFolderId: string
  label: string
  setFileInfo?: (type: string, name: string) => void
}

export default function ClientDocuments({ accountid, label, rootFolderId, setFileInfo }: ClientDocumentsProps) {
  const [selectedTab, setSelectedTab] = useQueryParamSubscription<ClientDocumentsTabKey>('docsTab', 'all')
  const [envelopeId, setEnvelopeId] = useQueryParamSubscription('envelopeId')
  const [documentCount, setDocumentCount] = useState(0)

  const TABS: Array<[ClientDocumentsTabKey, ClientDocumentsTabData]> = [
    ['all', { tabId: 'docs-tab', panelId: 'docs-panel', label: <span>All</span> }],
    [TAB_SIGATURE_DOCUMENTS, {
      tabId: 'review-tab',
      panelId: 'docs-panel',
      label: (
        <BadgeWithText text='Signature Documents ' badgeContent={ documentCount }></BadgeWithText>
      )
    }],
    ['setup', { tabId: 'setup-tab', panelId: 'users-panel', label: <span>Signature Setup</span> }],
    [TAB_EMBEDDED, { tabId: 'docs-embedded-tab', panelId: 'docs-embedded-panel', label: <span>Embedded</span> }],
  ]

  const getTabContent = (key: ClientDocumentsTabKey) => {
    if (key === 'all') {
      return <ClientDocumentBoxExplorer accountid={accountid} rootFolderId={rootFolderId} label={label} setFileInfo={setFileInfo} />
    }

    if (key === 'setup') {
      return <DocumentSignatureSetup accountId={accountid} />
    }

    if (key === TAB_SIGATURE_DOCUMENTS) {
      return <DocumentSignatureReview accountId={accountid} setDocumentCount={setDocumentCount} headerLabel='Signature Documents'/>
    }

    if (key === TAB_EMBEDDED) {
      return <CADocumentSigning accountId={accountid}/>
    }
  }

  const isTabSelected = (key: ClientDocumentsTabKey) => {
    if (key === selectedTab) {
      return true
    }

    if (key === TAB_SIGATURE_DOCUMENTS && (selectedTab === TAB_EMBEDDED)) {
      return true
    }

    return false
  }

  const onTabClick = (key: ClientDocumentsTabKey) => {
    setEnvelopeId(null)
    setSelectedTab(key)
  }

  return (
    <div className="c-client-documents">
      <div className="c-tab-list">
        <TabList>
          {TABS.map(([key, tab]) => (
            <Tab
              key={key}
              id={tab.tabId}
              panelId={tab.panelId}
              isSelected={isTabSelected(key)}
              label={tab.label}
              indexOrigin='docusign-tab'
              onClick={() => { onTabClick(key) }}
            />
          ))}
        </TabList>
      </div>
      {TABS.map(([key, tab]) => (
        <TabPanel key={key} id={tab.panelId} tabId={tab.tabId} isActive={selectedTab === key}>
          <div style={{ 'marginTop': '30px' }}>{getTabContent(key)}</div>
        </TabPanel>
      ))}
    </div>
  )
}