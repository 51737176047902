import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AccountClaims, AuthenticationResultWithClaims } from '@/shared/types/Claims'
import { PURGE } from 'redux-persist'

export interface IAuthState {
  idToken?: string;
  account?: AccountClaims;
  uniqueId?: string;
  tokenUpdated?: boolean;
}

export const initialState: IAuthState = {
  idToken: '',
  account: null,
  uniqueId: '',
  tokenUpdated: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<AuthenticationResultWithClaims>) {
      // sets state with token updated in case of new login, account switch or impersonation
      state.tokenUpdated = (action.payload.idToken !== state.idToken)
      
      // updates login state
      state.idToken = action.payload?.idToken
      state.account = action.payload?.account
      state.uniqueId = action.payload?.uniqueId
    },
    logout(state) {
      state.idToken = initialState.idToken
      state.account = initialState.account
      state.uniqueId = initialState.uniqueId
    },
    resetTokenUpdated(state) {
      state.tokenUpdated = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.idToken = initialState.idToken
        state.account = initialState.account
        state.uniqueId = initialState.uniqueId
      })
  }
})

export const { loginSuccess, logout } = authSlice.actions
export default authSlice.reducer