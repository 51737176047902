import { selectIsCAEmployee } from '@/features/auth/redux/authSelectors';
import { useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';

export default function DocumentSignatureLink({ match }: RouteComponentProps<{accountId: string, docusignId: string}>) {
  const { accountId, docusignId } = match.params
  const isCaEmployee = useSelector(selectIsCAEmployee)
  let documentSignatureRoute = ''

  if (isCaEmployee) {
    documentSignatureRoute = `/clients/${accountId}?tab=docs&docsTab=docsEmbedded&docusignId=${docusignId}`
  } else {
    documentSignatureRoute = `/documents?tab=docsEmbedded&docusignId=${docusignId}`
  }

  return (
    <Redirect to={documentSignatureRoute} />
  )

}
