import { useEffect, useState } from 'react'
import { Section } from '@/shared/components'
import Tab from '@/shared/components/Tabs/Tab'
import TabPanel from '@/shared/components/Tabs/TabPanel'
import TabList from '@/shared/components/Tabs/TabList'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'
import { IBoxFolderTreeItem } from '@/shared/types/swagger'
import BadgeWithText from '@/shared/components/BadgeLabel/BadgeWithText'
import RecentDocuments from '../RecentDocuments'
import PendingDocumentsSignature from '../PendingDocumentsSignature/PendingDocumentsSignature'

import './DashboardDocumentWidget.scss'

export type DashboardDocumentWidgetTabKey = 'recentDocuments' | 'pendingDocumentsSignature'
export type DashboardDocumentWidgetTabData = { tabId: string, panelId: string, label: JSX.Element | string }

export interface DashboardDocumentWidgetProps {
  accountId: string,
  dashboardSetting?: DashboardSettings,
  recentItems?: Array<IBoxFolderTreeItem>,
}

export default function DashboardDocumentWidget({ accountId, dashboardSetting, recentItems }: DashboardDocumentWidgetProps) {
  const [selectedPanel, setSelectedPanel] = useState<DashboardDocumentWidgetTabKey>('recentDocuments')
  const headerLabel = 'Documents'
  const isRecentDocumentAvailable = dashboardSetting && dashboardSetting.enableClientDashboardRecentDocuments
  const [documentCount, setDocumentCount] = useState(0)

  useEffect(() => {
    setSelectedPanel(isRecentDocumentAvailable ? 'recentDocuments' : 'pendingDocumentsSignature')
  }, [isRecentDocumentAvailable])

  const TABS: Array<[DashboardDocumentWidgetTabKey, DashboardDocumentWidgetTabData]> = [
    ['recentDocuments', { tabId: 'recent-documents-tab', panelId: 'docs-panel', label: 'Recent' }],
    ['pendingDocumentsSignature', {
      tabId: 'pending-documents-signature-tab', panelId: 'users-panel', label: (
        <BadgeWithText text='Pending Signature ' badgeContent={documentCount}></BadgeWithText>
      )
    }],
  ]

  const tabs = TABS.filter(([key, tab]) => {
    if (tab.tabId === 'recent-documents-tab' && !isRecentDocumentAvailable) {
      return false
    }
    return true
  })

  function getPanelContentByKey(key: DashboardDocumentWidgetTabKey) {
    if (key === 'recentDocuments' && isRecentDocumentAvailable) {
      return <RecentDocuments />
    }

    if (key === 'pendingDocumentsSignature') {
      return <PendingDocumentsSignature setDocumentCount={setDocumentCount} accountId={accountId} />
    }
  }

  return (
    <Section className='c-dashboard-document-widget' title={headerLabel}>
      <TabList>
        {tabs.map(([key, tab]) => (
          <Tab
            key={key}
            id={tab.tabId}
            panelId={tab.panelId}
            isSelected={selectedPanel === key}
            label={tab.label}
            onClick={() => { setSelectedPanel(key) }}
          />
        ))}
      </TabList>
      {tabs.map(([key, tab]) => (
        <TabPanel key={key} id={tab.panelId} tabId={tab.tabId} isActive={selectedPanel === key}>
          <div style={{ 'marginTop': '30px' }}>{getPanelContentByKey(key)}</div>
        </TabPanel>
      ))}
    </Section>
  )
}
