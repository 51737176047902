import React, { useState } from 'react'
import { createTheme, IDropdownOption, ThemeProvider } from '@fluentui/react'
import { Card, Dropdown, Section } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'
import {
  BenchmarkEndNotesWithDownload, EndNotesResponse, GeneralDisclaimers, IPortfolioSettings, useFetchBenchmarkEndNotesWithDownload,
  useGetDisclaimersQuery, useGetEndNotesQuery,
} from '@/shared/api/services/portfolioService'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'

import './ClientPortfolioDisclosures.scss'

export interface ClientPortfolioDisclosuresProps {
  dashboardSettings: DashboardSettings,
  portfolioSettings: IPortfolioSettings,
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string,
  previewAccountId?: string,
}

const tableTitle = [
  { title: "End Notes", elementId: "end-notes-section" },
  { title: "Benchmark End Notes", elementId: "benchmark-end-notes-section" },
  { title: "Methodology & Calculations", elementId: "methodology-calculations" },
  { title: "Index Sources & Disclaimers", elementId: "index-sources-disclaimers" },
  { title: "General Disclaimers", elementId: "general-disclaimers-section" },
]

const ClientPortfolioDisclosures: React.FC<ClientPortfolioDisclosuresProps> = ({ portfolioId, asOfDate, sleeveId, previewAccountId }) => {
  const isMobile = useIsMobile('xs')
  const { data: endNotesData } = useGetEndNotesQuery({ portfolioId, asOfDate, accountId: previewAccountId })
  const { data: benchmarkEndNotesData } = useFetchBenchmarkEndNotesWithDownload({ portfolioId, asOfDate, previewAccountId })
  const { data: disclaimersData } = useGetDisclaimersQuery()
  const [ selectedMobileDropdownKey, setSelectedMobileDropdownKey ] = useState(0)
  const [ selectedTabTitleElementId, setSelectedTabTitleElementId ] = useState(tableTitle[0].elementId)
  const [ isProgrammaticScroll, setProgrammaticScroll] = useState<boolean>(false)  
  const mobileDropdownOptions: IDropdownOption[] = tableTitle.map((item, index) => {
    if ( (item.elementId === 'end-notes-section' && (endNotesData?.endNotes || endNotesData?.summary)) ||
      (item.elementId === 'benchmark-end-notes-section' && benchmarkEndNotesData?.length > 0) ||
      (item.elementId === 'methodology-calculations'  && disclaimersData?.find(dmd => dmd?.name?.includes('Methodology and Calculations'))) || 
      (item.elementId === 'index-sources-disclaimers' && disclaimersData?.find(dmd => dmd?.name?.includes('Index Sources'))) || 
      (item.elementId === 'general-disclaimers-section' && disclaimersData?.find(dmd => dmd?.name?.includes('General Disclaimers'))) ) {
        return ({
          key: index,
          text: item.title,
          ...item
        })
      }
  }).filter(item => !!item)

  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  const subHeader = 'End Notes, Methodology & Calculations, Index Sources & Disclaimers, and General Disclaimers are as of the latest date available on the dashboard. '
    + 'Benchmark End Notes are as of the date selected by the user.'

  const onTableOfContentDesktopSelection = (tableOfContentElementId: string, tableOfContentTitle: string) => {
    const element = document.querySelector("#" + tableOfContentElementId)
    if (element) {
      setProgrammaticScroll(true)
      setTimeout(() => {
        element.scrollIntoView()
      })
    }
    setSelectedTabTitleElementId(tableOfContentElementId)
  }

  const onTableOfContentMobileSelection = (tableOfContentElementId: string, selectedOption: IDropdownOption) => {
    const element = document.querySelector("#" + tableOfContentElementId)
    if (element) {
      setProgrammaticScroll(true)
      setTimeout(() => {
        element.scrollIntoView()
      })
    }
    setSelectedMobileDropdownKey(selectedOption?.key as number)
    setSelectedTabTitleElementId(tableOfContentElementId)
  }

  const customTheme = createTheme({
    components: {
      Dropdown: {
        styles: {
          dropdownItem: {
            textAlign: 'start',
            backgroundColor: '#fff', 
            selectors: {
              ':hover': {
                backgroundColor: '#fff',
              },
            },
          },
        },
      },
    },
  });

  const showDropdownMobile = () => {
    if (isMobile) {
      return (
        <ThemeProvider theme={customTheme}>
          <Dropdown
            selectedKey={selectedMobileDropdownKey}
            className='DisclosuresDropdown'
            options={mobileDropdownOptions}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
              onTableOfContentMobileSelection(option['elementId'], option)
            }}
          />
        </ThemeProvider>
      )
    }
  }

  const onContentScroll = (event: UIEvent) => {
    if (isProgrammaticScroll) {
      setProgrammaticScroll(false)
      setTimeout(() => {
        window.scrollTo(0, 0)
      })
    }
    let visibleTableOfContent
    const mainContentElement = event.target as HTMLElement
    const mainContentRect = mainContentElement.getBoundingClientRect()
    const mainContentTopPosition = mainContentRect.top + 60
    const mainContentBottomPosition = mainContentRect.bottom

    for (let index = 0; index < tableTitle.length; index++) {
      const currentElement = document.querySelector('#' + tableTitle[index].elementId)

      if (currentElement) {
        const currentElementRect = currentElement.getBoundingClientRect()
        const currentElementTopPosition = currentElementRect.top
        const currentElementBottomPosition = currentElementRect.bottom
        const isCurrentElementTopBetweenMainContentTopAndBottomPosition = ((currentElementTopPosition >= mainContentTopPosition) && (currentElementTopPosition <= mainContentBottomPosition))
        const isCurrentElementBottomBetweenMainContentTopAndBottomPosition = ((currentElementBottomPosition >= mainContentTopPosition) && (currentElementBottomPosition <= mainContentBottomPosition))
        const isCurrentElementContentOverlappingContentTopAndBottomPosition = ((currentElementTopPosition < mainContentTopPosition) && (currentElementBottomPosition > mainContentBottomPosition))
        const isCurrentElementVisible = isCurrentElementTopBetweenMainContentTopAndBottomPosition
          || isCurrentElementBottomBetweenMainContentTopAndBottomPosition
          || isCurrentElementContentOverlappingContentTopAndBottomPosition
        if (isCurrentElementVisible) {
          visibleTableOfContent = tableTitle[index]
          break
        }
      }
    }

    if (!mainContentElement.scrollTop) {
      setSelectedTabTitleElementId(tableTitle[0].elementId)
      setSelectedMobileDropdownKey(mobileDropdownOptions[0].key as number)
    } else {
      if (visibleTableOfContent) {
        setSelectedMobileDropdownKey(mobileDropdownOptions.find(o => o.text === visibleTableOfContent.title)?.key as number)
        setSelectedTabTitleElementId(visibleTableOfContent.elementId)
      } else {
        setSelectedTabTitleElementId(tableTitle[0].elementId)
        setSelectedMobileDropdownKey(mobileDropdownOptions[0].key as number)
      }
    }
  }

  return (
    <Card ariaLabel='Disclosures'>
      <Section title='Disclosures' className='DisclosuresTab' subHeader={subHeader} actions={[showDropdownMobile()]}>
        <div className="page-wrapper">
          <div className="base-wrapper">
            <div className="page-content-wrapper">
              <div className="main">
                {isMobile ? null : (
                  <Menu
                    onTableOfContentDesktopSelection={onTableOfContentDesktopSelection}
                    tableTitle={tableTitle} 
                    endNotesData={endNotesData} 
                    benchmarkEndNotesData={benchmarkEndNotesData} 
                    disclaimersData={disclaimersData}
                    selectedTabTitleElementId={selectedTabTitleElementId}
                  />
                )}
                <Content endNotesData={endNotesData} benchmarkEndNotesData={benchmarkEndNotesData} asOfDate={asOfDate}
                  disclaimersData={disclaimersData} onContentScroll={onContentScroll} selectedTabTitleElementId={selectedTabTitleElementId} />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Card>
  )
}

const Menu: React.FC<{
  endNotesData: EndNotesResponse,
  benchmarkEndNotesData: BenchmarkEndNotesWithDownload[],
  disclaimersData: GeneralDisclaimers[],
  selectedTabTitleElementId: string,
  onTableOfContentDesktopSelection: (elementId: string, title: string) => void
  tableTitle: any[]
}> = ({ endNotesData, benchmarkEndNotesData, disclaimersData, selectedTabTitleElementId, onTableOfContentDesktopSelection, tableTitle }) => {
  const tableTitleFiltered = tableTitle.filter((item, index) => {
    return (item.elementId === 'end-notes-section' && (endNotesData?.endNotes || endNotesData?.summary)) ||
     (item.elementId === 'benchmark-end-notes-section' && benchmarkEndNotesData?.length > 0) ||
     (item.elementId === 'methodology-calculations'  && disclaimersData?.find(dmd => dmd?.name?.includes('Methodology and Calculations'))) || 
     (item.elementId === 'index-sources-disclaimers' && disclaimersData?.find(dmd => dmd?.name?.includes('Index Sources'))) || 
     (item.elementId === 'general-disclaimers-section' && disclaimersData?.find(dmd => dmd?.name?.includes('General Disclaimers'))) 
  });
  return (
    <div className="menu">
      <div className="table-of-contents-header">Table of Contents</div>
      <div className="table-of-contents-list-wrapper">
        <ul className="nav nav-pills flex-column mb-auto table-of-contents-list">
          {tableTitleFiltered.map((item, index) => {
            return (
              <li key={index} className='menu-item-title'>
                <a
                  id={`${item.elementId}-table-of-content-list-item`}
                  className={`nav-link link-body-emphasis table-of-content-list-item menu-item-title ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}
                  href="javascript:void(0)"
                  onClick={() => onTableOfContentDesktopSelection(item.elementId, item.title)}
                >
                  <span className="table-of-content-title">{item.title}</span>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  )
}

const Content: React.FC<{ 
  endNotesData: EndNotesResponse,
  benchmarkEndNotesData: BenchmarkEndNotesWithDownload[],
  disclaimersData: GeneralDisclaimers[],
  asOfDate: string,
  selectedTabTitleElementId,
  onContentScroll: (event) => void,
 }> = ({ endNotesData, benchmarkEndNotesData, disclaimersData, asOfDate, selectedTabTitleElementId, onContentScroll }) => {
  const buildContent = (item) => {
    function extractCDATA(xmlString) {
      try {
        // Parse the XML string into a DOM object
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "application/xml");

        // Get all <Fd> elements
        const fdElements = xmlDoc.getElementsByTagName("Fd");

        // Initialize an array to hold the extracted CDATA text
        const cdataTexts = [];
        const dateParts = asOfDate.split('-')
        const year = parseInt(dateParts[0], 10)
        // Loop through all <Fd> elements
        for (let i = 0; i < fdElements.length; i++) {
            const fdElement = fdElements[i];

            // Check if the <Fd> element contains CDATA
            if (fdElement.firstChild && fdElement.firstChild.nodeType === Node.CDATA_SECTION_NODE) {
              const nodeValue = `${fdElement.firstChild.nodeValue}`
              if (nodeValue && !nodeValue.match(/^[0-9]$/)) {
                cdataTexts.push(fdElement.firstChild.nodeValue);
              }
            }
        }

        return cdataTexts.reduce((acc, value) => {
          return acc + `<p>${value.replaceAll(/\/year\(\$\w+\$\)/g, year)}</p>`
        }, '')
      } catch (error) {
        console.error('Error while extracting data from Disclaimers.', error)
      }
    } 

    if (item.elementId === 'end-notes-section' && (endNotesData?.endNotes || endNotesData?.summary)) {
      return (
        <div>
          <h1 className={`term-of-service-title-1 ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}>{item?.title}</h1>
          <p dangerouslySetInnerHTML={{ __html: extractCDATA(endNotesData?.summary?.value) }}></p>
          {endNotesData?.endNotes.map((note, noteIndex) => (
            <p key={noteIndex}>{note?.order}. {note?.content}</p>
          ))}
        </div>
      )
    } else if (item.elementId === 'benchmark-end-notes-section' && benchmarkEndNotesData?.length > 0) {
      return (
        <div>
          <h1 className={`term-of-service-title-1 ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}>{item.title}</h1>
          {benchmarkEndNotesData?.map((note, noteIndex) => {
            const { downloadBinary, orientation } = note
            if (!downloadBinary) {
              return null
            }
            const pdfDataUrl = `data:application/pdf;base64,${downloadBinary}`
            const width = '100%'
            const height = '500px'

            return <embed src={pdfDataUrl} key={noteIndex} type='application/pdf' width={width} height={height} />
          })}
        </div>
      )
    } else if (item.elementId === 'methodology-calculations'  && disclaimersData?.find(dmd => dmd?.name?.includes('Methodology and Calculations'))) {
      return (
        <div>
          <h1 className={`term-of-service-title-1 ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}>{item.title}</h1>
          {disclaimersData?.map((note, noteIndex) => {
            if (note?.name.includes('Methodology and Calculations')) {
              return (
                <p key={noteIndex} dangerouslySetInnerHTML={{ __html: extractCDATA(note?.value) }}></p>
              )
            }
          })}
        </div>
      )
    } else if (item.elementId === 'index-sources-disclaimers' && disclaimersData?.find(dmd => dmd?.name?.includes('Index Sources'))) {
      return (
        <div>
          <h1 className={`term-of-service-title-1 ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}>{item.title}</h1>
          {disclaimersData?.map((note, noteIndex) => {
            if (note?.name.includes('Index Sources')) {
              return (
                <p key={noteIndex} className="indexes-source">
                  <p className="index-source-title">The Investment Performance Report was prepared using a subset of the listed Index Data providers below:</p>
                  <p className="index-source-body" dangerouslySetInnerHTML={{ __html: extractCDATA(note?.value) }}></p>
                </p>
              )
            } else if (note?.name.includes('Commentary - Index Disclaimers')) {
              return (
                <p key={noteIndex} dangerouslySetInnerHTML={{ __html: extractCDATA(note?.value) }}></p>
              )
            }
          })}
        </div>
      )
    } else if (item.elementId === 'general-disclaimers-section' && disclaimersData?.find(dmd => dmd?.name?.includes('General Disclaimers'))) {
      return (
        <div>
          <h1 className={`term-of-service-title-1 ${item?.elementId === selectedTabTitleElementId ? 'active' : ''}`}>{item.title}</h1>
          {disclaimersData?.map((note, noteIndex) => {
            if (note?.name.includes('General Disclaimers')) {
              return (
                <p key={noteIndex} dangerouslySetInnerHTML={{ __html: extractCDATA(note?.value) }}></p>
              )
            }
          })}
        </div>
      )
    }

  }
  return (
    <div className="content" onScroll={(event) => onContentScroll(event)}>
      <div id="overlay-container"></div>
      <div className="content-wrapper">
        {tableTitle.map((item, index) => (
          <div key={index} id={item.elementId} className="term-of-service-wrapper">
            {buildContent(item)}
          </div>
        ))}
      </div>
      <div id="api"></div>
    </div>
  )
}

export default ClientPortfolioDisclosures
