import dayjs from 'dayjs'
import { useMemo } from 'react'
import { getFileExtension  } from 'box-ui-elements/es/utils/file'
import { useGetBoxRecentFilesQuery, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import { Section, List } from '@/shared/components'
import useAccessRulesQuery from '@/features/auth/hooks/useAccessRulesQuery'
import SkeletonDashboardDocuments from '../DashboardDocumentWidget/SkeletonDashboardDocuments'

const RecentDocuments = () => {
  const { data: boxTokenResponse } = useGetBoxTokenQuery(null)
  const navAccessState = useAccessRulesQuery()
  const skipRecentFetch: boolean = !boxTokenResponse?.accessToken || navAccessState?.data?.documents === false
  const { data: recentItemsState, isSuccess, isLoading, isError, isFetching } = useGetBoxRecentFilesQuery(null, { skip: skipRecentFetch })
  
  const listItems = useMemo(() => {
    if (!recentItemsState) {
      return []
    }

    return recentItemsState.map(({ item }) => ({ 
      id: item.id,
      date: dayjs(item.modifiedAt ?? item.createdAt).toDate(),
      title: item.name,
      href: `/documents/${item.id}`,
      actionTitle: '',
      actionHref: `/documents/${item.id}`,
      actionIconName: getFileExtension(item.name)
    }))
  }, [recentItemsState])

  const Content = useMemo(() => {
    return (
      <div>
        {
          isSuccess && !recentItemsState?.length 
          ? <p className='c-recent-documents__empty'>You don&#39;t have any documents at the moment.</p>
          : <List useBoxIcon items={listItems} href='/documents' expandTitle="View all Documents" />
        }
      </div>
    )
  }, [listItems, recentItemsState?.length, isSuccess])

  if (isError || navAccessState?.isError || !navAccessState?.data?.documents || isLoading) {
    return null
  }

  return (
    <>
      { !isFetching ? (
        <Section className='c-recent-documents' title="Recent Documents">
          {Content}
        </Section>) : (<SkeletonDashboardDocuments></SkeletonDashboardDocuments>)
      }    
    </>
  )
}

export default RecentDocuments
