import { emmaApiService } from '.'

export interface IYourTeam {
  accountId?: string;
  caId : number,
  peopleId: number;
  fullName: string;
  jobTitle: string;
  email: string;
  image?: string;
  imageContentType?: string;
  sortIndex?: number;
}

export interface TeamMapping {
  accountId: string;
  peopleId: number;
  sortIndex?: number;
}

export interface SaveTeamMemberRequest {
  accountId: string;
  teamMembers: TeamMapping[];
}

const teamService = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['YourTeam'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        searchTeamMember: build.mutation<Array<IYourTeam>, string>({
          query: (searchText) => (
            {
              url: `/team/search?name=${searchText}`,
              method: 'GET'
            }
          ),
        }),
        getYourTeam: build.query<Array<IYourTeam>, string>({
          query: (accountId) => ({
            url: `/team/${accountId}`,
            method: 'GET'
          }),
          providesTags: ['YourTeam']
        }),
        saveTeamMembers: build.mutation<Array<IYourTeam>, SaveTeamMemberRequest>({
          query: (data) => (
            { 
              url: `/team/saveTeamMembers`,
              method: 'POST',
              data: data
            }
          ),
          invalidatesTags: ['YourTeam']
        }),
      }
    }
  })

export const {
  useGetYourTeamQuery,
  useSearchTeamMemberMutation, 
  useSaveTeamMembersMutation,
} = teamService
