
import React, { useEffect, useRef, useState } from 'react'
import { DocuSignAccountInfo, useGetAccountInfoQuery, usePostResetViewUrlMutation, useUpdateStatusMutation } from '@/shared/api/services/docuSignService'

import './DocuSignEmbeddedSigningView.scss'

declare global {
  interface Window {
    DocuSign: any
  }
}

export interface DocuSignEmbeddedSigningViewCloseEvent {
  docusignId: string,
  status: string,
  isError?: boolean,
}

export interface DocuSignEmbeddedSigningViewProps {
  signingUrl: string,
  docusignId: string,
  accountId: string,
  onClose?: (event: DocuSignEmbeddedSigningViewCloseEvent) => void,
}

const DocuSignEmbeddedSigningView: React.FC<DocuSignEmbeddedSigningViewProps> = ({ signingUrl, docusignId, accountId, onClose }) => {
  const { data: docuSignAccountInfo } = useGetAccountInfoQuery()
  const [resetViewUrl] = usePostResetViewUrlMutation()
  const { clientId } = docuSignAccountInfo || {} as DocuSignAccountInfo
  const [ isSigningViewLoaded, setSigningViewLoaded ] = useState(false)
  const [ isSigningViewCompleted, setSigningViewCompleted ] = useState(false)
  const signingViewLoadedRef = useRef(false)
  const signingViewCompletedRef = useRef(false)
  const [updateStatus] = useUpdateStatusMutation()
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Define the event handler
    const handleBeforeUnload = async () => {
      try {
        const response = await resetViewUrl({
          accountId,
          docusignId,
        })
      } catch (error) {
        console.error('Failed to reset view url on page unload.', error)
      }
    }

    // Add the event listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    };
  }, []) // Empty dependency array ensures this runs only once

  useEffect(() => {
    return () => {
      const handleBeforeUnmount = async () => {
        try {
          const response = await resetViewUrl({
            accountId,
            docusignId,
          })
        } catch (error) {
          console.error('Failed to reset view url on component unmount.', error)
        }
      }

      // skip action in case of missing DocuSign id or Account Id
      if (!docusignId || !accountId) {
        return
      }

      // skip action in case of missing DocuSign signing view references
      if (!signingViewLoadedRef || !signingViewCompletedRef) {
        return 
      }

      // only triggers reset view url if DocuSign signing view is loaded, not completed and component was suddenly unmounted
      if (signingViewLoadedRef.current && !signingViewCompletedRef.current) {
        handleBeforeUnmount()
      }
    }
  }, [docusignId, accountId, signingViewLoadedRef, signingViewCompletedRef])

  useEffect(() => {
    if (!signingUrl || !clientId || signingViewLoadedRef.current) {
      return
    }

    if (window.DocuSign) {
      window.DocuSign.loadDocuSign(clientId)
        .then((docusign: any) => {
          const signing = docusign.signing({
            url: signingUrl,
            displayFormat: 'focused',
            style: {
              branding: {
                primaryButton: {
                  backgroundColor: '#333',
                  color: '#fff',
                },
              },
              signingNavigationButton: {
                finishText: 'Complete Signing',
                position: 'bottom-left',
              },
            },
          })

          // mark signing view as loaded
          signingViewLoadedRef.current = true
          setSigningViewLoaded(true)

          signing.on('sessionEnd', (event: any) => {
            let status = ''

            switch (event.sessionEndType) {
              case 'signing_complete':
                status = 'Completed'
                break
              case 'cancel':
                status = 'Cancelled'
                break
              case 'decline':
                status = 'Declined'
                break
              case 'exception':
                status = 'Exception'
                break
              case 'fax_pending':
                status = 'Fax_pending'
                break
              case 'session_timeout':
                status = 'Timeout'
                break
              case 'ttl_expired':
                status = 'Expired'
                break
              case 'Viewing_complete':
                status = 'Viewed'
                break
              default:
                status = 'Unknown'
            }

            // mark signing view as completed
            signingViewCompletedRef.current = true
            setSigningViewCompleted(true)

            // Call the updateStatus mutation with the determined status
            updateStatus({
              docusignId,
              newStatus: status,
            })
              .then(() => {
                // close event
                if (onClose) {
                  onClose({
                    docusignId,
                    status,
                  })
                }
              })
              .catch((error: any) => {
                console.error('Error updating status:', error)
                onClose({
                  docusignId,
                  status,
                  isError: true,
                })
              })
          })

          // init signing view on div container
          signing.mount(containerRef.current)
        })
        .catch((error: any) => {
          console.error('Error loading DocuSign:', error)
        })
    }
  }, [signingUrl, docuSignAccountInfo, updateStatus, signingViewLoadedRef, setSigningViewLoaded, signingViewCompletedRef, setSigningViewCompleted])

  return (
    <div
      className="DocuSignEmbeddedSigningView"
      ref={containerRef}
    />
  )
}

export default DocuSignEmbeddedSigningView