import { RouteComponentProps } from 'react-router'
import { ContentPreview } from 'box-ui-elements/es/elements'
import { IntlProvider } from 'react-intl'
import { useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import { MainLayout, GeneralError } from '@/shared/components'
import Skeleton from 'react-loading-skeleton'
import { contentSidebarProps, FOLDER_QUERY_KEY } from '@/features/document/constants'
import useQuery from '@/shared/hooks/useQuery'
import DocumentBreadcrumbs from '@/features/document/components/DocumentBreadcrumbs'
import { useCallback, useState } from 'react'
import { DefaultButton, IBreadcrumbItem, MessageBarType } from '@fluentui/react'
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { BoxFile } from '@/shared/types/box'
import { useDispatch, useSelector } from 'react-redux'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { selectAccountId, selectTokenUpdated } from '@/features/auth/redux/authSelectors'
import { getSharedDocumentLink } from '../SharedDocumentLink/SharedDocumentLink'
import useAccountName from '@/features/auth/hooks/useAccountName'
import useClientId from '@/features/auth/hooks/useClientId'
import { trackDocumentDownloadFileAction, trackDocumentViewFileAction } from '@/utils/components/datadog/DatadogSessionProvider'
import { invalidateBoxServices } from '@/shared/api/services/invalidationService'

const home: IBreadcrumbItem = {
  key: 'documents',
  text: 'All Documents',
  href: '/documents'
}

export default function Document({ match }: RouteComponentProps<{fileId?: string}>) {
  // A single file can be previewed or a collection. Either way a fileId is required
  // when a collection query is provided the fileid of the item in the collection will
  // be displated on load. This id will be updated when navigating through the collection
  const { fileId } = match.params
  const query: URLSearchParams = useQuery()
  const dispatch = useDispatch<appDispatch>()
  const collection: Array<string> = query.has('collection') ? query.get('collection').split(',') : undefined
  const accountId = useSelector(selectAccountId)
  const { accountName } = useAccountName()
  const { clientId } = useClientId(accountId)
  const [parentBreadcrumb, setParentBreadcrumb] = useState<IBreadcrumbItem>(null)
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<IBreadcrumbItem>(null)
  const [folderId, setFolderId] = useState(null)
  const { data, isLoading, isUninitialized, isError, refetch } = useGetBoxTokenQuery(null)
  const showSkelly = isLoading || isUninitialized
  const [hasPermission, setHasPermission] = useState(true)
  const isTokenUpdated = useSelector(selectTokenUpdated)
  
  if (isTokenUpdated) {
    invalidateBoxServices(1000)
    dispatch({
      type: 'auth/resetTokenUpdated',
    })
  }

  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(getSharedDocumentLink(accountId, folderId, fileId))
      dispatch(createUIMessage({ 
        key: 'boxfoliderclipboard', 
        content: `Successfully copied a link to file <b>${currentBreadcrumb?.text}</b> to your clipboard`,
        messageBarType: MessageBarType.success
      }))
    } catch (error) {
      console.error('Error while creating share link.', error)
    }
  }, [currentBreadcrumb?.text, dispatch])

  const onContentPreviewFileDownload = (file: BoxFile) => {
    if (file) {
      trackDocumentDownloadFileAction({
        accountId,
        accountName,
        clientId,
        folderId: parentBreadcrumb && parentBreadcrumb.key,
        folderName: parentBreadcrumb && parentBreadcrumb.text,
        fileId: file.id,
        fileName: file.name,
      })
    }
  }

  return (
    <IntlProvider locale="en">
      <MainLayout>
        <div className='c-documents-header'>
          <DocumentBreadcrumbs items={[home, parentBreadcrumb, currentBreadcrumb].filter(Boolean)} />
          { hasPermission && <DefaultButton text='Copy link to file' onClick={handleCopy} /> }
        </div>
        <div className='c-documents'>
          {showSkelly && <Skeleton height={900} width="100%" />}
          {isError && <GeneralError title='Failed to get box token' onClick={refetch} />}
          {isError || showSkelly ? null : (
            <ContentPreview 
              token={data.accessToken} 
              hasHeader={true}
              collection={collection}
              contentSidebarProps={contentSidebarProps} 
              fileId={fileId}
              onDownload={onContentPreviewFileDownload}
              requestInterceptor={(config: AxiosRequestConfig) => {
                try {
                  if (/https:\/\/api\.box\.com\/2\.0\/files\/\d+/.test(config.url) && config.method.toLowerCase() === 'get') {
                    if (typeof config?.params?.fields === 'string') {
                      config.params.fields += ',parent'
                    }
                  }
                } catch (error) {
                  console.error('Error parsing request fields.', error)
                }

                return config
              }}
              responseInterceptor={(response: AxiosResponse<BoxFile>) => {
                try {              
                  if (response?.data?.parent) {
                    const { parent } = response.data
                    if (parent.id !== '0') {
                      setFolderId(parent.id)
                      setParentBreadcrumb({
                        key: parent.id,
                        text: parent.name,
                        href: `/documents?${FOLDER_QUERY_KEY}=${parent.id}`
                      })
                    }
                  }
                  if (response?.data?.name) {
                    const _fileInfo: typeof currentBreadcrumb = {
                      text: response.data.name,
                      key: response.data.id,
                      isCurrentItem: true
                    }
                    setCurrentBreadcrumb(_fileInfo)
                    if (response && response.data) {
                      trackDocumentViewFileAction({
                        accountId,
                        accountName,
                        clientId,
                        folderId: response.data && response.data.parent && response.data.parent.id,
                        folderName: response.data && response.data.parent && response.data.parent.name,
                        fileId: response?.data?.id,
                        fileName: response?.data.name,
                      })  
                    }
                  }
                  if (response?.status === 404) {
                    setHasPermission(false);
                    //@ts-expect-error overriting 
                    (response as AxiosError<BoxFile>).code = 'forbidden_by_policy'
                  } else {
                    // sets permission when status is not 404 / forbidden_by_policy
                    if (!hasPermission) {
                      setHasPermission(true)
                    }
                  }
                } catch (error) {
                  console.error('Error parsing response data.', error)
                }
                return response
              }}
            />
          )}
        </div>
      </MainLayout>
    </IntlProvider>
  )
}
