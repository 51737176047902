import React from 'react'
import './Tab.scss'

interface ITabProps {
  isSelected: boolean;
  id: string;
  panelId: string;
  label: any
  onClick: React.HTMLAttributes<HTMLButtonElement>['onClick']
  indexOrigin?: string
}

export default function Tab(props: ITabProps) {
  const { id, isSelected, panelId, label, onClick, indexOrigin } = props

  return (
    <button 
      type="button" 
      role="tab" 
      className={`c-tab ${indexOrigin}`}
      id={id} 
      aria-selected={isSelected} 
      aria-controls={panelId} 
      {...(isSelected ? {} : { tabIndex: -1 })}
      onClick={onClick}
    >
      <span className='c-tab__label'>{label}</span>
    </button>
  )
}