import { CurrencyColumn, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { ICurrency, IPerformanceData, IPortfolioSettings, getGridExcelStyles } from '@/shared/api/services/portfolioService'
import { GridApi } from 'ag-grid-community'
import { PerformanceSleeveColumn } from '@/screens/ClientPortfolio/Components/Grid/Column/PerformanceSleeveColumn'

import './TopHoldingsGrid.scss'

export interface TopHoldingsGridProps {
  rows: IPerformanceData[],
  portfolioSettings: IPortfolioSettings,
  sendGridApi: (gridApi : GridApi) => void,
}

const TopHoldingsGrid = ({
    rows,
    portfolioSettings,
    sendGridApi,
}: TopHoldingsGridProps) => {
  const { currency, dateFormat } = portfolioSettings || {} as IPortfolioSettings
  const { symbol: currencySymbol } = currency || {} as ICurrency
  const getCurrencySymbol = (data) => {
    return data?.CurrencySymbol
  }
  const ongridInit=(event : DataGridInitEvent) =>{
    const { gridApi } = event
    sendGridApi(gridApi)
  }
    return (
      <DataGrid
        showToolBar={false}
        rows={rows}
        enablePagination={false}
        domLayout='normal'
        className='TopFiveHolding'
        onInit={ongridInit}
        excelStyles={getGridExcelStyles({
          currencySymbol,
          dateFormat,
        })}
      >
        <PerformanceSleeveColumn disableFilterColumn={true} title='' field='Name' checkboxSelection={false}  />
        <CurrencyColumn title='MARKET VALUE' disableFilterColumn={true} field='MV' width={150} getCurrencySymbol={getCurrencySymbol}/>
        <PercentageColumn title='ALLOCATION (%)' disableFilterColumn={true} field='mvPercentage' width={150} showPercentageSymbol={false} valueGetter={(h) => {
            if (h) {
              const value = h.mvPercentage
              if ((value != null) && (value !== undefined)) {
                return (parseFloat(value) * 100).toFixed(1)
              }
            }
          }} />
      </DataGrid>
    )
}

export default TopHoldingsGrid