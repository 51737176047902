import { useLayoutEffect, useRef, useState } from 'react';
import { ComboBox, IComboBoxOption, IComboBoxStyles } from '@fluentui/react'
import { DocuSignTemplate, useGetTemplatesQuery } from '@/shared/api/services/docuSignService';

import './DocuSignTemplateSelector.scss'

export interface DocuSignTemplateSelectorProps {
  disabled: boolean,
  selectedOption?: DocuSignTemplate,
  onValueChange: (selectedOption: DocuSignTemplate) => void,
}

const DocuSignTemplateSelector = ({ disabled = false, selectedOption, onValueChange }: DocuSignTemplateSelectorProps) => {
  const dropdownRef = useRef(null)
  const { data } = useGetTemplatesQuery()
  const selectedKey = selectedOption && selectedOption.templateId
  const [ isInputInitialized, setInputInitialized ] = useState(false)
  const inputInitializedRef = useRef(false)

  useLayoutEffect(() => {
    if (dropdownRef) {
      const container = dropdownRef.current as HTMLElement
      
      if (container) {
        const baseContainer = container.querySelector('.ms-ComboBox') as HTMLElement
        
        if (baseContainer) {
          const input = container.querySelector('input') as HTMLElement
          
          if (input) {
            // does not reprocess drodpwn input
            if (inputInitializedRef.current) {
              return
            }

            // mark input as initialized
            inputInitializedRef.current = true
            setInputInitialized(true)

            // set input with read only mode
            input.setAttribute('readonly', 'readonly')

            // setup event to avoid text selection on input            
            input.addEventListener('focus', (event) => {
              event.preventDefault()
              input.blur()
            })

            input.addEventListener('mousedown', (event) => {
              event.preventDefault()
              input.blur()
            })
          }
        }
      }
    }
  },[dropdownRef, isInputInitialized, inputInitializedRef, setInputInitialized])

  const boxFolderPermisionDropdownStyles: Partial<IComboBoxStyles> = { 
    root: {
      width: 380,
      outline: 'none',
      lineHeight: 'normal'
    },
    optionsContainerWrapper: {
      maxHeight: 216,
      width: 380
    },
  }

  const boxFolderPermisionDropdownOptions = (data || []).map(t => ({
    key: t.templateId,
    text: t.name || t.description,
    templateId:t.templateId,
  })).filter(t => t.text !== "")

  const onBoxFolderPermisionDropdownChange = (option?: IComboBoxOption) => {
    onValueChange(option as any)
  }

  return (
    <ComboBox
      ref={dropdownRef}
      disabled={disabled}
      className={`DocuSignTemplateSelector ${selectedOption ?  'has-value' : ''}`}
      selectedKey={selectedKey}
      options={boxFolderPermisionDropdownOptions}
      styles={boxFolderPermisionDropdownStyles}
      autoComplete='off'
      allowFreeform={false}
      useComboBoxAsMenuWidth={false}
      placeholder='Select Option'
      onChange={(event, option) => onBoxFolderPermisionDropdownChange(option)}
      calloutProps={{
          className: 'docusign-template-selector-callout'
        }}
    />
  )
}

export default DocuSignTemplateSelector
