import React from 'react'
import './BadgeWithText.scss'

interface BadgeWithTextProps {
  text: string;
  badgeContent: number;
  color?: 'default' | 'primary' | 'secondary' | 'error';
}

const BadgeWithText: React.FC<BadgeWithTextProps> = ({ text, badgeContent, color = 'primary' }) => {
  const bagdeContentDigits = `${badgeContent || ''}`.length
  return (
    <>
      <span className='badge-with-text'>
        <span className='bagde-text'>{text}</span>
        { badgeContent ? (<span className={`badge-content digits-${bagdeContentDigits}`}>{badgeContent}</span>) : null }
      </span>
    </>
  );
};

export default BadgeWithText;