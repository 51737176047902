import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { PrimaryButton, MessageBarType } from '@fluentui/react'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import { Card, Section } from '@/shared/components'
import DocuSignEmbeddedSigningView, { DocuSignEmbeddedSigningViewCloseEvent } from '@/shared/components/DocuSign/DocuSignEmbeddedSigningView/DocuSignEmbeddedSigningView'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { useGetDocusignViewUrlQuery } from '@/shared/api/services/docuSignService'
import { selectIsImpersonating } from '@/features/auth/redux/authSelectors'
import { TAB_EMBEDDED } from '../../Documents'

import './DocumentSigning.scss'
export interface DocumentSigningProps {
  accountId: string
}

const UI_MESSAGE_DISMISS_TIMEOUT = 3000

export default function DocumentSigning({ accountId }: DocumentSigningProps) {
  const isImpersonating = useSelector(selectIsImpersonating)
  const [tab] = useQueryParamSubscription('tab')
  const [docusignId] = useQueryParamSubscription('docusignId')
  const { data: viewURL } = useGetDocusignViewUrlQuery(docusignId, { skip: !docusignId })
  const dispatch = useDispatch()
  const history = useHistory()
  const documentListUrl = `/documents?tab=pendingSignature`

  if (!docusignId || !viewURL || (tab !== TAB_EMBEDDED)) {
    return null
  }

  // [CP-679] - CA Users impersonating client users should not be able to Sign Document
  if (isImpersonating) {
    return <Redirect to='/documents' />
  }

  const onClose = (event: DocuSignEmbeddedSigningViewCloseEvent) => {
    if (event) {
      if (event.isError) {
        dispatch(createUIMessage({
          key: 'postSigningViewCloseError',
          content: 'Error while processing Document Signature.',
          messageBarType: MessageBarType.error,
          autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
        }))
      } else if (event.status === 'Completed') {
        dispatch(createUIMessage({
          key: 'postSigningViewClose',
          content: 'Document Signing Complete.',
          messageBarType: MessageBarType.success,
          autoDismissAfter: UI_MESSAGE_DISMISS_TIMEOUT,
        }))
      }
    }
    history.push(documentListUrl)
  }

  const backSigningBackButtonClick = () => {
    history.push(documentListUrl)
  }

  return (
    <IntlProvider locale='en'>
      <Section className='DocumentSigning'>
        <Card ariaLabel='' heading=''>
          <div>
            <DocuSignEmbeddedSigningView
              signingUrl={viewURL}
              docusignId={docusignId}
              accountId={accountId}
              onClose={onClose}
            />
            <PrimaryButton onClick={backSigningBackButtonClick}>
              Back
            </PrimaryButton>
          </div>
        </Card>
      </Section>
    </IntlProvider>
  )
}