import { emmaApiService } from "."

export interface DocuSignAccountInfo {
  clientId: string,
}

export interface DocuSignEnvelopeUrlRequest {
  envelopeId: string,
  recipientEmail: string,
  recipientName: string,
  returnUrl: string,
}

export interface Signer {
  email: string,
  name: string,
  firstName?: string,
  fullName?: string,
  lastName?: string,
}

export interface SignerDetails {
  signer: Signer,
  caId: number,
  contactId: string,
  contactKey: number,
  accountId: string,
}

export interface DocuSignEnvelopePostRequest {
  accountId: string,
  templateId: string,
  returnUrl: string,
  envelopeRequest: {
    signers: SignerDetails[],
  },
}

export interface DocuSignSenderViewResponse {
  envelopeId: string,
  url: string,
}

export interface UpdateDocusignStatusRequest {
  docusignId: string,
  newStatus: string,
}

export interface ResetViewUrlRequest {
  docusignId: string,
  accountId: string,
}

export interface DocuSignOwner {
  accountId?: string,
  accountName?: string,
  activationAccessCode?: string,
  email?: string,
  errorDetails?: string,
  ipAddress?: string,
  loginStatus?: string,
  membershipId?: string,
  sendActivationEmail?: string,
  uri?: string,
  userId?: string,
  userName?: string,
  userStatus?: string,
  userType?: string,
}

export interface DocuSignTemplate {
  brandId: string,
  description: string,
  envelopeId: string,
  envelopeUri: string,
  folderId: string,
  folderIds: string[],
  name: string,
  ownerUserId: string,
  status: string,
  templateId: string,
  templatesUri: string,
  uri: string,
}

// Define the response type for the new query
export interface DocuSignSummaryResponse {
  docusignId: string,
  templateName: string,
  creationDate: string,
  documentStatus: string,
  viewURL: string,
  expiredBy: string,
  signers: {
    name: string,
    email: string,
    accountId: string,
    contactId: string,
    status: string,
    viewURL: string,
  }[],
}

export interface DocuSignSyncRequest {
  accountId: string,
  envelopeId: string,
}

export interface DocuSignEnvelope {
  anySigner: string,
  attachmentsUri: string,
  brandId: string,
  certificateUri: string,
  completedDateTime: string,
  copyRecipientData: string,
  createdDateTime: string,
  declinedDateTime: string,
  deletedDateTime: string,
  deliveredDateTime: string,
  documentBase64: string,
  documentsCombinedUri: string,
  documentsUri: string,
  emailSettings: any,
  emailSubject: string,
  envelopeAttachments: any[],
  envelopeCustomMetadata: any,
  envelopeDocuments: any[],
  envelopeId: string,
  envelopeIdStamping: string,
  envelopeLocation: string,
  envelopeMetadata: any,
  envelopeUri: string,
  expireAfter: string,
  expireDateTime: string,
  expireEnabled: string,
  externalEnvelopeId: string,
  folders: any[],
  lastModifiedDateTime: string,
  location: string,
  lockInformation: any,
  messageLock: string,
  notification: Notification,
  notificationUri: string,
  recipients: any,
  recipientsLock: string,
  recipientsUri: string,
  sender: any,
  sentDateTime: string,
  signerCanSignOnMobile: string,
  signingLocation: string,
  status: string,
  templatesUri: string,
  transactionId: string,
}

const mainBoxServices = emmaApiService
  .enhanceEndpoints({ addTagTypes: ['DocuSignAccountInfo', 'DocuSignTemplate', 'DocuSignSummary', 'DocuSignEnvelope'] })
  .injectEndpoints({
    endpoints(build) {
      return {
        // RTK Queries for GET, HEAD requests
        getAccountInfo: build.query<DocuSignAccountInfo, void>({
          query: () => ({
            url: `/DocuSign/AccountInfo`,
            method: 'GET',
          }),
          providesTags: ['DocuSignAccountInfo'],
        }),
        getTemplates: build.query<DocuSignTemplate[], void>({
          query: () => ({
            url: `/DocuSign/Templates`,
            method: 'GET',
          }),
          providesTags: ['DocuSignTemplate'],
        }),
        getDocuSignSummary: build.query<DocuSignSummaryResponse[], { accountId: string }>({
          query: ({ accountId }) => ({
            url: `/DocuSign/Summary?accountId=${accountId}`,
            method: 'GET',
          }),
          providesTags: ['DocuSignSummary'],
        }),
        getDocuSignEmployeeSummary: build.query<DocuSignSummaryResponse[], { accountId: string }>({
          query: ({ accountId }) => ({
            url: `/DocuSign/Employee/Summary?accountId=${accountId}`,
            method: 'GET',
          }),
          providesTags: ['DocuSignSummary'],
        }),
        getConsoleViewUrl: build.query<string, void>({
          query: () => ({
            url: `/DocuSign/ConsoleView`,
            method: 'GET',
          }),
        }),
        getDocusignViewUrl: build.query<string, string>({
          query: (docusignId) => ({
            url: `/DocuSign/${docusignId}/ViewUrl`,
            method: 'GET',
          }),
        }),

        // RTK Mutations for POST and PUT queries
        updateStatus: build.mutation<void, UpdateDocusignStatusRequest>({
          query: (request) => ({
            url: `/DocuSign/UpdateStatus`,
            method: 'PUT',
            data: request,
          }),
          invalidatesTags: ['DocuSignSummary'],
        }),
        postEnvelopeViewUrl: build.mutation<string, DocuSignEnvelopePostRequest>({
          query: (envelopeRequest) => ({
            url: `/DocuSign/ViewUrls`,
            method: 'POST',
            data: envelopeRequest,
          }),
          invalidatesTags: ['DocuSignSummary'],
        }),
        postSenderViewUrl: build.mutation<DocuSignSenderViewResponse, DocuSignEnvelopePostRequest>({
          query: (envelopeRequest) => ({
            url: `/DocuSign/SenderView`,
            method: 'POST',
            data: envelopeRequest,
          }),
        }),
        postSyncDocuSignEnvelope: build.mutation<DocuSignEnvelope, DocuSignSyncRequest>({
          query: (request) => ({
            url: `/DocuSign/Envelopes/${request.envelopeId}/Sync`,
            method: 'POST',
            data: {
              accountId: request.accountId,
            }
          }),
          invalidatesTags: ['DocuSignSummary'],
        }),
        postResetViewUrl: build.mutation<void, ResetViewUrlRequest>({
          query: (request) => ({
            url: `/DocuSign/${request.docusignId}/ViewUrl/Reset`,
            method: 'POST',
            data: {
              accountId: request.accountId,
            }
          }),
          invalidatesTags: ['DocuSignSummary'],
        }),
      }
    }
  })

export const {
  useGetAccountInfoQuery,
  useGetTemplatesQuery,
  useGetDocuSignSummaryQuery,
  useGetDocuSignEmployeeSummaryQuery,
  useGetConsoleViewUrlQuery,
  useGetDocusignViewUrlQuery,
  useUpdateStatusMutation,
  usePostEnvelopeViewUrlMutation,
  usePostSenderViewUrlMutation,
  usePostSyncDocuSignEnvelopeMutation,
  usePostResetViewUrlMutation,
} = mainBoxServices